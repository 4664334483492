<template>
  <v-container>
    <v-btn-toggle mandatory class="d-flex flex-column flex-md-row">
      <v-btn
        class="d-flex rounded-xl mr-md-2 mb-3 mb-md-0"
        elevation="0"
        color="secondary"
        outlined
        @click="btnValueClick(allValue)"
      >
        Todas
      </v-btn>
      <v-btn
        class="d-flex rounded-xl mr-md-2 mb-3 mb-md-0 ma-0"
        elevation="0"
        color="secondary"
        value="5"
        outlined
        @click="btnValueClick(fiveStar)"
      >
        5
        <v-icon small class="secondary--text ml-1">fa-star</v-icon>
      </v-btn>
      <v-btn
        class="d-flex rounded-xl mr-md-2 mb-3 mb-md-0 ma-0"
        elevation="0"
        color="secondary"
        value="4"
        outlined
        @click="btnValueClick(fourStar)"
      >
        4
        <v-icon small class="secondary--text ml-1">fa-star</v-icon>
      </v-btn>
      <v-btn
        class="d-flex rounded-xl mr-md-2 mb-3 mb-md-0 ma-0"
        elevation="0"
        color="secondary"
        value="3"
        outlined
        @click="btnValueClick(threeStar)"
      >
        3
        <v-icon small class="secondary--text ml-1">fa-star</v-icon>
      </v-btn>
      <v-btn
        class="d-flex rounded-xl mr-md-2 mb-3 mb-md-0 ma-0"
        elevation="0"
        color="secondary"
        value="2"
        outlined
        @click="btnValueClick(twoStar)"
      >
        2
        <v-icon small class="secondary--text ml-1">fa-star</v-icon>
      </v-btn>
      <v-btn
        class="d-flex rounded-xl mr-md-2 mb-3 mb-md-0 ma-0"
        elevation="0"
        value="1"
        outlined
        @click="btnValueClick(oneStar)"
      >
        1
        <v-icon small class="secondary--text ml-1">fa-star</v-icon>
      </v-btn>
    </v-btn-toggle>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    allValue: "ShowAll",
    fiveStar: 5,
    fourStar: 4,
    threeStar: 3,
    twoStar: 2,
    oneStar: 1,
  }),

  computed: {},

  methods: {
    btnValueClick(btnValue) {
      this.$emit("clickedBtn", btnValue);
    },
  },
};
</script>

<style scoped>
.active {
  color: red;
}
</style>
