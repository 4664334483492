<template>
  <div>
    <h2 class="my-5 text-center text-sm-left">Transacciones</h2>
    <div>
      <v-row class="mx-2 d-none d-sm-flex" align="center" no-gutters>
        <v-col cols="2">
          <div class="text-h3 font-weight-bold">Cliente</div>
        </v-col>
        <v-col cols="2">
          <div class="text-h3 font-weight-bold">Servicio</div>
        </v-col>
        <v-col cols="2">
          <div class="text-h3 font-weight-bold">Recibo</div>
        </v-col>
        <v-col cols="2">
          <div class="text-h3 font-weight-bold">Fecha de pago</div>
        </v-col>
        <v-col cols="2">
          <div class="text-h3 font-weight-bold">Fecha de evento</div>
        </v-col>
        <v-col cols="2">
          <div class="text-h3 font-weight-bold">Monto pagado</div>
        </v-col>
      </v-row>
      <v-expansion-panels v-model="panel" focusable multiple class="mb-5">
        <v-expansion-panel
          v-for="(item, id) in concludedServices"
          :key="id"
          class="mb-3"
        >
          <v-expansion-panel-header>
            <Transactions-header :service="item" />
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Transactions-row :service="item" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <Pagination
        :page="page"
        :arr-length="concludedServices.length"
        :per-page="perPage"
        @page-changed="changePage"
      />
    </div>
  </div>
</template>
<script>
import Pagination from "../../Shared/Pagination.vue";
import TransactionsHeader from "./TransactionsHeader.vue";
import TransactionsRow from "./TransactionsRow.vue";
export default {
  components: {
    Pagination,
    TransactionsHeader,
    TransactionsRow,
  },
  props: {
    concludedServices: { type: Array, required: true },
  },
  data: () => ({
    // Expansion panel data
    panel: [],
    // Pagination data
    page: 1,
    perPage: 7,
  }),
  computed: {
    pagServices() {
      let start = (this.page - 1) * this.perPage;
      let end = this.page * this.perPage;
      if (this.concludedServices.length < end) {
        end = this.concludedServices.length;
      }
      return this.concludedServices.slice(start, end);
    },
    categories: function () {
      let _categories = [];
      this.concludedServices.forEach((ser) => {
        if (ser.category) {
          let repeated = _categories.includes(ser.category);
          if (!repeated) {
            _categories.push(ser.category);
          }
        }
      });
      return _categories;
    },
  },

  methods: {
    changePage(page) {
      this.page = page;
    },
  },
};
</script>
<style lang="scss" scoped></style>
