<template>
  <div class="mt-3">
    <template>
      <h2>Datos del {{ currentYear }}</h2>
      <br />
      <v-simple-table>
        <template>
          <tbody>
            <tr>
              <td class="py-7">Contrataciones</td>
              <td align="end">{{ yearContracts }}</td>
            </tr>
            <tr>
              <td class="py-7">Solicitudes recibidas</td>
              <td align="end">{{ yearRequests }}</td>
            </tr>
            <tr>
              <td class="py-7">Cotizaciones enviadas</td>
              <td align="end">{{ yearQuotations }}</td>
            </tr>
            <tr>
              <td class="py-7">Media de ingreso por servicio</td>
              <td align="end">{{ numberToCurrency(yearMedianIncome) }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </template>
  </div>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  props: {
    currentYear: {
      type: Number,
      default: 0,
    },
    yearContracts: {
      type: Number,
      default: 0,
    },
    yearRequests: {
      type: Number,
      default: 0,
    },
    yearQuotations: {
      type: Number,
      default: 0,
    },
    yearMedianIncome: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>
<style scoped>
tr:hover {
  background-color: transparent !important;
}

.v-data-table tr {
  vertical-align: 0;
}
.v-input--selection-controls {
  margin-top: 0;
  padding: 0;
}
</style>
