<template>
  <v-container>
    <v-row class="mt-sm-10 ml-sm-16 ml-md-5">
      <v-col class="text-center text-sm-left pl-md-10" cols="4" sm="3" md="4"
        ><h2>
          {{ overallGrade }}
          <v-icon color="primary">mdi-star</v-icon>
        </h2>
        <div class="text-subtitle-1">Calificacion</div>
      </v-col>

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="4" sm="4" md="4">
        <h2>{{ totalComments }}</h2>
        <div class="text-subtitle-1">Comentarios</div>
      </v-col>

      <v-divider class="hidden-xs-only" vertical></v-divider>

      <v-col class="text-center text-sm-left pl-md-10" cols="3" sm="3" md="4"
        ><h2>{{ totalEvaluations }}</h2>
        <div class="text-subtitle-1" color="black">
          <div class="text-subtitle-1">Evaluaciones</div>
        </div></v-col
      >
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    overallGrade: {
      type: Number,
      default: 0,
    },
    totalComments: {
      type: Number,
      default: 0,
    },
    totalEvaluations: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({}),
};
</script>
