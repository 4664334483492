<template>
  <div class="mt-3">
    <v-row v-for="(payment, id) in service.payments" :key="id">
      <!-- Number of payments -->
      <v-col cols="12" sm="2">
        <div class="text-body font-weight-bold">No. de pago</div>
        <div class="text-body-1 pl-5">
          {{ `${id + 1}/${service.payments.length}` }}
        </div>
      </v-col>
      <!-- Payment method -->
      <v-col cols="12" sm="2">
        <div class="text-body-1 font-weight-bold">Método de pago</div>
        <div class="text-body-1">Visa ****{{ payment.cardNumber }}</div>
        <p class="text-body-1">{{ payment.payerName }}</p>
      </v-col>
      <v-col cols="12" sm="2" class="hidden-xs-only"> </v-col>
      <!-- Payment date -->
      <v-col cols="12" sm="2">
        <div class="text-body-1 font-weight-bold">Fecha de pago</div>
        <div class="text-body-1">
          {{ dateFormatter(payment.date) }}
        </div>
      </v-col>
      <v-col cols="2" class="hidden-xs-only"> </v-col>
      <v-col cols="12" sm="2">
        <div class="text-body-1 font-weight-bold hidden-sm-and-up">
          Monto pagado
        </div>
        <div class="text-body-1 ml-1">
          <b>Cantidad pagada</b>
          {{ numberToCurrency(payment.amountPayed) }}
        </div>
        <v-divider class="hidden-sm-and-up mt-5" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter";

export default {
  name: "ConcludedPaymentRow",
  props: {
    service: { type: Object, required: true },
  },
  methods: {
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>
<style lang="scss" scoped></style>
