<template>
  <v-container>
    <v-container v-if="clientEvaluations.length">
      <v-card
        v-for="(client, index) in clientEvaluations"
        :key="'client' + index"
        width="100%"
        elevation="2"
        class="rounded-lg pa-5 mb-5"
      >
        <v-container class="d-flex justify-space-between">
          <div>
            <b>{{ clientEvaluations[index].clientName }}</b>
            <br />
            {{ clientEvaluations[index].evaluationDate }}
          </div>
          <div>
            <v-img
              :width="width"
              class="rounded-circle"
              :src="`${clientEvaluations[index].clientImg}`"
              :lazy-src="`${clientEvaluations[index].clientImg}`"
            ></v-img>
          </div>
        </v-container>
        <v-rating
          :value="parseInt(clientEvaluations[index].starRating)"
          background-color="descent lighten-2"
          color="secondary"
          readonly
          dense
          hover
          length="5"
          size="20"
        ></v-rating>
        <div class="text-subtitle-2 mt-2">Calificación general</div>
        <v-btn text class="pa-0"><u>Ver detalles de servicio</u></v-btn>
        <div class="text-subtitle-2 mt-2">
          {{ clientEvaluations[index].clientMsg }}
        </div>
      </v-card>
    </v-container>
    <v-container v-else>
      <v-card
        width="100%"
        elevation="2"
        class="rounded-lg pa-5 mb-5"
        align="center"
      >
        No se encontraron evaluaciones</v-card
      >
    </v-container></v-container
  >
</template>

<script>
export default {
  props: {
    clientEvaluations: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    width: 63,
  }),

  computed: {},

  methods: {},
};
</script>

<style scoped></style>
