<template>
  <v-row no-gutters align="center" class="my-4">
    <v-col cols="12" sm="2">
      <div class="text-body-1 pl-5 hidden-xs-only">
        {{ service.name }}
      </div>
      <p class="text-body-1 hidden-sm-and-up">
        <b>No. Pagos: </b> {{ service.payments.length }}
      </p>
    </v-col>
    <v-col cols="12" sm="2">
      <div class="text-body-1 hidden-xs-only">{{ service.service }}</div>
      <p class="text-body-1 hidden-sm-and-up">
        <b>Servicio: </b> {{ service.service }}
      </p>
    </v-col>
    <v-col cols="12" sm="2">
      <div
        class="text-body-1 text-decoration-underline font-weight-bold hidden-xs-only ml-2"
      >
        #{{ service.receipt }}
      </div>
      <p class="text-body-1 hidden-sm-and-up">
        <b>Recibo </b> {{ service.receipt }}
      </p>
    </v-col>
    <v-col cols="12" sm="2">
      <div class="text-body-1 hidden-xs-only ml-3">
        {{ dateFormatter(service.dateEvent) }}
      </div>
      <p class="text-body-1 hidden-sm-and-up">
        <b>Fecha evento: </b> {{ dateFormatter(service.dateEvent) }}
      </p>
    </v-col>
    <v-col cols="12" sm="2">
      <div class="text-body-1 hidden-xs-only ml-6">
        {{ dateFormatter(service.dateLimit) }}
      </div>
      <p class="text-body-1 hidden-sm-and-up">
        <b>Fecha liquidación: </b> {{ dateFormatter(service.dateLimit) }}
      </p>
    </v-col>
    <v-col cols="12" sm="1">
      <div class="text-body-1 ml-7 hidden-xs-only">{{ total }}</div>
      <p class="text-body-1 hidden-sm-and-up"><b>Total: </b> {{ total }}</p>
    </v-col>
  </v-row>
</template>
<script>
import { numberToCurrency } from "@/Utils/numberFormatter.js";
import { convertUnixToDDMMYYYY } from "@/Utils/dateConverter";

export default {
  name: "ConcludedPaymentHeader",
  props: {
    service: { type: Object, required: true },
  },
  computed: {
    total: function () {
      return numberToCurrency(this.service.totalAmount);
    },
  },
  methods: {
    dateFormatter(unixTime) {
      return convertUnixToDDMMYYYY(unixTime);
    },
  },
};
</script>
<style lang="scss" scoped></style>
