<template>
  <v-container fluid ma-0 pa-0>
    <!-- <h2
      v-if="dataArray[yearIndex].serviceName === 'Todos'"
      class="mt-5 text-center text-sm-left"
    >
      Ingresos
    </h2>
    <h2
      v-else-if="dataArray[yearIndex].serviceName !== 'Todos'"
      class="mt-5 text-center text-sm-left"
    >
      {{ dataArray[yearIndex].serviceName }}
    </h2> -->

    <!-- ERASE -->
    <h2 class="mt-5 text-center text-sm-left">Ingresos</h2>
    <!-- ERASE -->

    <div class="text-sm-left">
      Ingresos registrados en el año
      {{ dataArray.year }}
    </div>
    <v-flex d-flex>
      <v-layout wrap>
        <div class="my-3">
          Pagado
          <h3>{{ numberToCurrency(totalPayment) }}</h3>
        </div>
        <div class="ml-16 my-3">
          Ingreso Previsto
          <h3>{{ numberToCurrency(totalPerceivedPayment) }}</h3>
        </div>
      </v-layout>
    </v-flex>
    <DxChart id="chart" :data-source="dataArray.content">
      <DxCommonSeriesSettings argument-field="state" type="stackedbar" />
      <DxSeries
        value-field="paymentValue"
        name="Pagado"
        stack="payment"
        color="rgba(33, 20, 95, 0.7)
"
      />
      <DxSeries
        value-field="pendingValue"
        name="Ingreso previsto"
        stack="payment"
        color="rgba(98, 37, 130, 0.7)"
      />
      <DxExport :enabled="true" />
      <DxTooltip :enabled="true" />
      <DxLegend
        position="hide"
        horizontal-alignment="center"
        vertical-alignment="bottom"
      />
    </DxChart>
    <v-tabs v-model="year" centered>
      <v-tab @click="selectValueYear(lastCurrentYear)">
        {{ lastCurrentYear }}
      </v-tab>
      <v-tab @click="selectValueYear(currentYear)">
        {{ currentYear }}
      </v-tab>
    </v-tabs>
  </v-container>
</template>
<script>
import {
  DxChart,
  DxSeries,
  DxLegend,
  DxCommonSeriesSettings,
  DxExport,
  DxTooltip,
} from "devextreme-vue/chart";
import { numberToCurrency } from "@/Utils/numberFormatter.js";

export default {
  components: {
    DxChart,
    DxSeries,
    DxLegend,
    DxCommonSeriesSettings,
    DxExport,
    DxTooltip,
  },
  props: {
    // dataArray: {
    //   type: Array,
    //   default: function () {
    //     return [];
    //   },
    // },
    dataArray: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data: () => ({
    year: null,
    yearIndex: 0,
    currentYear: new Date().getFullYear(),
    lastCurrentYear: new Date().getFullYear() - 1,
    totalPayment: 3000,
    totalPerceivedPayment: 100000,
  }),
  methods: {
    selectValueYear(yearValue) {
      this.$emit("yearFilter", yearValue);
    },
    numberToCurrency(number) {
      return numberToCurrency(number);
    },
  },
};
</script>
<style>
#chart {
  height: 440px;
}
</style>
