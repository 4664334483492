<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar page="provider" />
    </div>
    <v-container class="body-container">
      <v-container class="pb-0">
        <v-row>
          <v-col cols="12">
            <h1 class="mt-10">Rendimiento</h1>
          </v-col>
          <v-col cols="12" sm="10">
            <v-card class="elevation-0">
              <v-tabs background-color="white" show-arrows>
                <v-tabs-slider
                  color="primary"
                  class="text-decoration-none"
                ></v-tabs-slider>
                <v-tab @click="tabSelectorIncome()"> Ingresos </v-tab>
                <v-tab @click="tabSelectorEvaluation()"> Evaluaciones </v-tab>
              </v-tabs>
            </v-card>
          </v-col>
          <v-col cols="12" sm="2">
            <div align="center">
              <v-btn plain color="primary">
                <div class="my-8">
                  <v-icon width="100%">mdi-export-variant</v-icon>
                  <p text--black>Exportar</p>
                </div>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>
      <v-row v-if="currentItem === 'Income'">
        <!--  UNCOMMENT WHEN WORKING WITH THE FILTERS -->
        <!-- <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="pb-0 mb-0">
          <Graph-filter
            :search-select-service="incomeServices"
            @serviceFilter="handleServiceChange"
          />
        </v-col> -->
        <v-col cols="12" class="pt-0 mt-0">
          <v-container>
            <Payment-chart
              :data-array="incomeShow"
              @yearFilter="handleFilter"
            />
          </v-container>
        </v-col>
        <v-col cols="12">
          <v-container>
            <Transactions :concluded-services="concludedServices" />
          </v-container>
        </v-col>
        <v-col cols="12">
          <v-container>
            <Year-data
              :current-year="yearData.currentYear"
              :year-contracts="yearData.contracts"
              :year-requests="yearData.requests"
              :year-quotations="yearData.quotations"
              :year-median-income="yearData.incomeMedian"
            />
          </v-container>
        </v-col>
      </v-row>
      <v-row v-else-if="currentItem === 'Evaluation'">
        <v-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" class="order-1">
          <Evaluation-filter @clickedBtn="handleBtnFilter" />
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="8" class="order-2"> </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" class="order-4 order-md-3">
          <v-container>
            <Evaluation-card :client-evaluations="clientEvaluationsFiltered" />
          </v-container>
        </v-col>
        <v-col cols="12" xs="12" sm="12" md="6" class="order-3 order-md-4">
          <Dashboard
            :overall-grade="dashBoardInfo.overallGrade"
            :total-comments="dashBoardInfo.totalComments"
            :total-evaluations="dashBoardInfo.totalEvaluations"
          />
        </v-col>
      </v-row>
    </v-container>
    <Footer class="mt-0" />
  </v-container>
</template>

<script>
import Dashboard from "../../components/Provider/Performance/Dashboard.vue";
import EvaluationCard from "../../components/Provider/Performance/EvaluationCard.vue";
import EvaluationFilter from "../../components/Provider/Performance/EvaluationFilter.vue";
// import GraphFilter from "../../components/Provider/Performance/GraphFilter.vue";
import PaymentChart from "../../components/Provider/Performance/PaymentChart.vue";
import Transactions from "../../components/Provider/Performance/Transactions.vue";
import YearData from "../../components/Provider/Performance/YearData.vue";
import Navbar from "../../components/Provider/Shared/Navbar.vue";
import Footer from "../../components/Shared/Footer.vue";
export default {
  components: {
    Navbar,
    Footer,
    PaymentChart,
    Transactions,
    YearData,
    EvaluationFilter,
    EvaluationCard,
    Dashboard,
    // GraphFilter,
  },
  data: () => ({
    currentItem: "Income",
    btnValue: "showAll",
    filterService: "All",
    clientEvaluationsFiltered: [],
    // Income graphs
    incomeServices: [
      { service: "All", serviceName: "Todos" },
      { service: "SomeService", serviceName: "Un servicio" },
    ],
    serviceValue: "All",
    incomeShow: {},
    incomeData2021: {
      service: "All",
      serviceName: "Todos",
      year: 2021,
      content: [
        {
          stateNum: 0,
          state: "ENE",
          paymentValue: 3000,
          pendingValue: 0,
        },
        {
          stateNum: 1,
          state: "FEB",
          paymentValue: 2700,
          pendingValue: 0,
        },
        {
          stateNum: 2,
          state: "MAR",
          paymentValue: 2500,
          pendingValue: 0,
        },
        {
          stateNum: 3,
          state: "ABR",
          paymentValue: 2200,
          pendingValue: 0,
        },
        {
          stateNum: 4,
          state: "MAY",
          paymentValue: 2000,
          pendingValue: 0,
        },
        {
          stateNum: 5,
          state: "JUN",
          paymentValue: 1900,
          pendingValue: 0,
        },
        {
          stateNum: 6,
          state: "JUL",
          paymentValue: 1500,
          pendingValue: 0,
        },
        {
          stateNum: 7,
          state: "AGO",
          paymentValue: 900,
          pendingValue: 0,
        },
        {
          stateNum: 8,
          state: "SEP",
          paymentValue: 1600,
          pendingValue: 0,
        },
        {
          stateNum: 9,
          state: "OCT",
          paymentValue: 1900,
          pendingValue: 0,
        },
        {
          stateNum: 10,
          state: "NOV",
          paymentValue: 200,
          pendingValue: 2500,
        },
        {
          stateNum: 11,
          state: "DEC",
          paymentValue: 500,
          pendingValue: 3000,
        },
      ],
    },
    incomeData2022: {
      service: "All",
      serviceName: "Todos",
      year: 2022,
      content: [
        {
          stateNum: 0,
          state: "ENE",
          paymentValue: 300,
          pendingValue: 0,
        },
        {
          stateNum: 1,
          state: "FEB",
          paymentValue: 270,
          pendingValue: 0,
        },
        {
          stateNum: 2,
          state: "MAR",
          paymentValue: 250,
          pendingValue: 0,
        },
        {
          stateNum: 3,
          state: "ABR",
          paymentValue: 220,
          pendingValue: 0,
        },
        {
          stateNum: 4,
          state: "MAY",
          paymentValue: 20,
          pendingValue: 0,
        },
        {
          stateNum: 5,
          state: "JUN",
          paymentValue: 10,
          pendingValue: 0,
        },
        {
          stateNum: 6,
          state: "JUL",
          paymentValue: 300,
          pendingValue: 0,
        },
        {
          stateNum: 7,
          state: "AGO",
          paymentValue: 90,
          pendingValue: 0,
        },
        {
          stateNum: 8,
          state: "SEP",
          paymentValue: 100,
          pendingValue: 0,
        },
        {
          stateNum: 9,
          state: "OCT",
          paymentValue: 190,
          pendingValue: 0,
        },
        {
          stateNum: 10,
          state: "NOV",
          paymentValue: 20,
          pendingValue: 250,
        },
        {
          stateNum: 11,
          state: "DEC",
          paymentValue: 55,
          pendingValue: 300,
        },
      ],
    },
    incomeData2021Service: {
      service: "SomeService",
      serviceName: "Un servicio",
      year: 2021,
      content: [
        {
          stateNum: 0,
          state: "ENE",
          paymentValue: 300,
          pendingValue: 0,
        },
        {
          stateNum: 1,
          state: "FEB",
          paymentValue: 20,
          pendingValue: 0,
        },
        {
          stateNum: 2,
          state: "MAR",
          paymentValue: 250,
          pendingValue: 0,
        },
        {
          stateNum: 3,
          state: "ABR",
          paymentValue: 220,
          pendingValue: 0,
        },
        {
          stateNum: 4,
          state: "MAY",
          paymentValue: 20,
          pendingValue: 0,
        },
        {
          stateNum: 5,
          state: "JUN",
          paymentValue: 10,
          pendingValue: 0,
        },
        {
          stateNum: 6,
          state: "JUL",
          paymentValue: 500,
          pendingValue: 0,
        },
        {
          stateNum: 7,
          state: "AGO",
          paymentValue: 90,
          pendingValue: 0,
        },
        {
          stateNum: 8,
          state: "SEP",
          paymentValue: 16,
          pendingValue: 0,
        },
        {
          stateNum: 9,
          state: "OCT",
          paymentValue: 100,
          pendingValue: 0,
        },
        {
          stateNum: 10,
          state: "NOV",
          paymentValue: 20,
          pendingValue: 200,
        },
        {
          stateNum: 11,
          state: "DEC",
          paymentValue: 50,
          pendingValue: 200,
        },
      ],
    },

    concludedServices: [
      {
        id: "1",
        name: "Nombre",
        service: "Boda Maggiore",
        dateLimit: "1636945896",
        dateEvent: "1636945896",
        category: "Boda",
        payedAmount: 3000,
        pendingAmount: 7000,
        totalAmount: 10000,
        receipt: 12345,
        payments: [
          {
            id: "1",
            cardNumber: "0066",
            payerName: "Veronica Ramirez C.",
            date: "1636945896",
            amountPayed: 3000,
          },
          {
            id: "2",
            cardNumber: "1234",
            payerName: "Veronica Ramirez C.",
            date: "1636945896",
            amountPayed: 7000,
          },
        ],
      },
      {
        id: "2",
        name: "Nombre",
        service: "Shower Maggiore",
        dateLimit: "1636945896",
        dateEvent: "1636945896",
        category: "Baby Shower",
        payedAmount: 3000,
        pendingAmount: 7000,
        totalAmount: 10000,
        receipt: 12345,
        payments: [
          {
            id: "1",
            cardNumber: "0066",
            payerName: "Veronica Ramirez C.",
            date: "1636945896",
            amountPayed: 3000,
          },
          {
            id: "2",
            cardNumber: "1234",
            payerName: "Veronica Ramirez C.",
            date: "1636945896",
            amountPayed: 7000,
          },
        ],
      },
    ],
    yearData: {
      currentYear: 2022,
      contracts: 35,
      requests: 20,
      quotations: 35,
      incomeMedian: 200,
    },
    clientEvaluations: [
      {
        id: 21,
        clientName: "Name McName",
        evaluationDate: "21 Julio 2021",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        starRating: 4,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus consequat accumsan, mauris a donec nunc, dui, nulla. Risus ullamcorper pretium sem posuere elementum pellentesque dui nulla sagittis. Dignissim at turpis vitae volutpat, viverra tortor, interdum. Et non bibendum mauris quis. Hendrerit elementum sit tellus ultricies mattis in ac.",
      },
      {
        id: 22,
        clientName: "Another name",
        evaluationDate: "18 Marzo 2021",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        starRating: 1,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus consequat accumsan, mauris a donec nunc, dui, nulla. Risus ullamcorper pretium sem posuere elementum pellentesque dui nulla sagittis. Dignissim at turpis vitae volutpat, viverra tortor, interdum. Et non bibendum mauris quis. Hendrerit elementum sit tellus ultricies mattis in ac.",
      },
      {
        id: 23,
        clientName: "Panfilo Lopez",
        evaluationDate: "04 Diciembre 2021",
        clientImg: "https://cdn.vuetifyjs.com/images/john.jpg",
        starRating: 5,
        clientMsg:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Senectus consequat accumsan, mauris a donec nunc, dui, nulla. Risus ullamcorper pretium sem posuere elementum pellentesque dui nulla sagittis. Dignissim at turpis vitae volutpat, viverra tortor, interdum. Et non bibendum mauris quis. Hendrerit elementum sit tellus ultricies mattis in ac.",
      },
    ],
    dashBoardInfo: {
      overallGrade: 4.5,
      totalComments: 5,
      totalEvaluations: 30,
    },
  }),

  created() {
    this.clientEvaluationsFiltered = this.clientEvaluations;
  },

  mounted() {
    this.incomeShow = this.incomeData2021;
  },

  methods: {
    tabSelectorIncome() {
      return (this.currentItem = "Income");
    },
    tabSelectorEvaluation() {
      return (this.currentItem = "Evaluation");
    },
    handleBtnFilter(btnValue) {
      if (btnValue === "ShowAll") {
        this.clientEvaluationsFiltered = this.clientEvaluations;
      } else {
        this.clientEvaluationsFiltered = this.clientEvaluations.filter(
          (evaluation) => evaluation.starRating == btnValue
        );
      }
    },

    // UNCOMMENT WHEN WORKING WITH THE FILTERS
    // handleServiceChange(serviceFilter) {
    //   this.filterService = serviceFilter;
    // },
    // handleFilter(filterYear) {
    //   if (filterYear == this.incomeData2021.year) {
    //     if (this.filterService == "All") {
    //       this.incomeShow = this.incomeData2021;
    //     } else if (this.filterService == "SomeService") {
    //       this.incomeShow = this.incomeData2021Service;
    //     }
    //   } else {
    //     if (this.filterService == "All") {
    //       this.incomeShow = this.incomeData2022;
    //     } else if (this.filterService == "SomeService") {
    //       this.incomeShow = {};
    //     }
    //   }
    // },
    handleFilter(filterYear) {
      if (filterYear == this.incomeData2021.year) {
        this.incomeShow = this.incomeData2021;
      } else {
        this.incomeShow = this.incomeData2022;
      }
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 1);
}
</style>
